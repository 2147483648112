import { useInfiniteQuery, useQuery, UseQueryOptions } from 'react-query'
import { ResourceListDataEntryV2ApiDto, ResourceFoundDataEntryV2ApiDto } from 'typescript-axios'
import { AxiosError } from 'axios'
import { getEntries, getEntry } from './api'
import queryKeys, { DataEntriesFilterProps } from './queryKeys'

const { dataEntriesPage, singleDataEntry } = queryKeys

export const useGetDataEntries = (
  filters: DataEntriesFilterProps,
  enabled?: boolean,
  refetchInterval?: number
) => {
  return useInfiniteQuery<
    ResourceListDataEntryV2ApiDto,
    AxiosError,
    ResourceListDataEntryV2ApiDto,
    ReturnType<typeof dataEntriesPage>
  >(dataEntriesPage(filters), ({ pageParam = 1 }) => getEntries({ page: pageParam, ...filters }), {
    getNextPageParam: (lastPage) => lastPage.paging.nextPage,
    staleTime: Infinity,
    enabled,
    refetchInterval,
    select: (data) => {
      const { pages, pageParams } = data

      // Calculate total items per page from the first page
      const itemsPerPage = pages[0]?.data?.length || 0

      const dataEntries = pages.flatMap((page) => page.data!)

      // Group entries by dataSourceId
      const groupedEntries = dataEntries.reduce((acc, entry) => {
        if (!acc[entry.dataSourceId!]) {
          acc[entry.dataSourceId!] = []
        }
        acc[entry.dataSourceId!].push(entry)
        return acc
      }, {} as Record<string, typeof dataEntries>)

      // Process each group to keep all non-claims and best claim
      const newDataEntries = Object.values(groupedEntries).flatMap((entries) => {
        const claimEntries = entries.filter((entry) => entry.dataOriginName === 'Claims')
        const nonClaimEntries = entries.filter((entry) => entry.dataOriginName !== 'Claims')

        if (claimEntries.length > 0) {
          // Sort claims by number of dataEntryValues (descending)
          const bestClaim = claimEntries.sort(
            (a, b) => (b.dataEntryValues?.length || 0) - (a.dataEntryValues?.length || 0)
          )[0]
          return [...nonClaimEntries, bestClaim]
        }

        return nonClaimEntries
      })

      // Distribute entries across pages
      const distributedPages = pages.map((page, index) => {
        const startIndex = index * itemsPerPage
        const pageEntries = newDataEntries.slice(startIndex, startIndex + itemsPerPage)

        return {
          ...page,
          data: pageEntries,
          paging: {
            ...page.paging,
            // Only set nextPage if there are more entries
            nextPage:
              startIndex + itemsPerPage < newDataEntries.length ? page.paging.nextPage : undefined,
          },
        }
      })

      return {
        pages: distributedPages,
        pageParams,
      }
    },
  })
}

export const useGetDataEntry = (
  id: string,
  options?: UseQueryOptions<
    ResourceFoundDataEntryV2ApiDto,
    AxiosError,
    ResourceFoundDataEntryV2ApiDto,
    ReturnType<typeof singleDataEntry>
  >
) => {
  return useQuery(singleDataEntry(id), () => getEntry(id), {
    ...options,
    staleTime: Infinity,
  })
}
